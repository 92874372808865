import { Alert, SecondaryButton } from '@designsforhealth/dfh-react-components'
import { useLocation } from '@gatsbyjs/reach-router'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { useClipboardCopy } from '../../hooks/useClipboardCopy'
import { setAnalyticsEvent } from '../../lib/analytics/state'

import copyIcon from '../../img/copy.png'

const StyledCopyArea = styled(SecondaryButton)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.coolGray.cool250};
  border-color: ${({ theme }) => theme.colors.coolGray.cool500};
  padding: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
  overflow: hidden;
`

const StyledClickToCopy = styled.div`
  font-family: ${({ theme }) => theme.fontFamilies.robotoCondensed};
  letter-spacing: normal;
  font-weight: bold;
  align-self: center;
  margin-bottom: 0;
  width: 70px;
  text-align: left;
`

const StyledCopyText = styled.div`
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  text-transform: none;
  letter-spacing: normal;
  font-weight: 400;
  background-color: ${({ theme }) => theme.colors.coolGray.cool400};
  border-color: ${({ theme }) => theme.colors.coolGray.cool400};
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  margin-left: 8px;
  margin-right: 8px;
  align-self: center;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.component.SecondaryButton.default.text};
`

const StyledCopyIcon = styled.img`
  height: 35px;
  width: 35px;
  align-self: center;
`

export interface ClickToCopyProps {
  copyText: string
}

const publishCopyEScriptEvent = ({
  copyText,
  version,
  campaign,
}: {
  copyText: string
  version?: string | null
  campaign?: string | null
}): void => {
  if (!version || !campaign) {
    return
  }

  setAnalyticsEvent({
    event: 'copy_escript',
    escript_campaign: campaign,
    escript_version: version,
    escript_link: copyText,
  })
}

const ClickToCopy: React.FC<ClickToCopyProps> = ({ copyText }) => {
  const location = useLocation()
  const params = useMemo(() => {
    const url = new URL(copyText)
    return new URLSearchParams(url.search)
  }, [copyText])

  const onCopy = useCallback(
    (text) => {
      if (location.pathname.includes('/escript')) {
        const campaign = params.get('ec')
        const version = params.get('v')
        publishCopyEScriptEvent({
          copyText: text,
          version,
          campaign,
        })
      }
    },
    [location.pathname, params],
  )

  const { copied, copyToClipboard, resetCopied } = useClipboardCopy({ text: copyText, onCopy })

  return (
    <>
      <StyledCopyArea onClick={copyToClipboard}>
        <StyledClickToCopy>Click to Copy</StyledClickToCopy>
        <StyledCopyText>{copyText}</StyledCopyText>
        <StyledCopyIcon src={copyIcon} />
      </StyledCopyArea>
      {copied && (
        <Alert type="success" onDismiss={resetCopied}>
          Link copied to clipboard!
        </Alert>
      )}
    </>
  )
}

export default ClickToCopy
